import { OlympiaApi } from '../app/api'
import { LessonsData, PaginationPayload } from '../types/serviceTypes'
import { AdminLogin } from './APIEndpoints'

export const lessonApi = OlympiaApi.injectEndpoints({
    endpoints: (builder) => ({
        getLessons: builder.query<LessonsData, PaginationPayload>({
            query: (variables) => ({document: AdminLogin, variables}),
            providesTags: ['Lesson']
        })
    })
})

export const {
    useLazyGetLessonsQuery,
} = lessonApi