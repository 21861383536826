import { DashboardTitleType } from '../../types/propTypes'
import { 
    NoticeIcon, 
    TrainingIcon, 
    ResourcesIcon, 
    CommunityIcon,
    UsersIcon
} from '../icons/icons'
import { COMMUNITY_BOARD_TITLES, NOTICE_BOARD_TITLES, RESOURCES_BOARD_TITLES, TRAINING_BOARD_TITLES, USERS_BOARD_TITLES } from './enums'

export const navbarTabs = [
    // {
    //     icon: NoticeIcon,
    //     page: 'notice',
    //     link: '/notice-board',
    // },
    {
        icon: TrainingIcon,
        page: 'training',
        link: '/training-board',
    },
    // {
    //     icon: ResourcesIcon,
    //     page: 'resources',
    //     link: '/resources-board',
    // },
    {
        icon: CommunityIcon,
        page: 'community',
        link: '/community-board',
    },
    // {
    //     icon: UsersIcon,
    //     page: 'users',
    //     link: '/users-board',
    // },
]

export const noticeBoardTitles: DashboardTitleType[] = [
    {
        title:      'Program Feedback',
        titlePage:  NOTICE_BOARD_TITLES.PROGRAM_FEEDBACK
    },
    {
        title:      'Help Tickets',
        titlePage:  NOTICE_BOARD_TITLES.HELP_TICKETS
    }
]

export const trainingBoardTitles: DashboardTitleType[] = [
    {
        title:      'All Exercises',
        titlePage:  TRAINING_BOARD_TITLES.PROGRAM
    },
    // {
    //     title:      'Workouts',
    //     titlePage:  TRAINING_BOARD_TITLES.WORKOUTS
    // },
    // {
    //     title:      'Exercises',
    //     titlePage:  TRAINING_BOARD_TITLES.EXERCISES
    // }
]

export const resourcesBoardTitles: DashboardTitleType[] = [
    {
        title:      'Courses',
        titlePage:  RESOURCES_BOARD_TITLES.COURSES
    },
    {
        title:      'Lessons',
        titlePage:  RESOURCES_BOARD_TITLES.LESSONS
    }
]

export const communityBoardTitles: DashboardTitleType[] = [
    {
        title:      'All Users',
        titlePage:  COMMUNITY_BOARD_TITLES.REPORTS
    },
    // {
    //     title:      'Groups',
    //     titlePage:  COMMUNITY_BOARD_TITLES.GROUPS
    // }
]

export const usersBoardTitles: DashboardTitleType[] = [
    {
        title:      'User Statistics',
        titlePage:  USERS_BOARD_TITLES.USER_STATISTICS
    },
    {
        title:      'All Users',
        titlePage:  USERS_BOARD_TITLES.ALL_USERS
    }
]

export const courseFocusList = [
    'Full Body',
    'Legs',
    'Cardio',
    'Push',
    'Pull', 
]

export const type = [
    'Fat Burn',
    'Strength'
]

export const isLightWeightPB = [
    'True',
    'False'
]

export const Gender = [
    'Male',
    'Female'
]
