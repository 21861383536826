import { useMemo, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { debounce } from 'lodash'
import { OlympiaSearchbar, OlympiaTitleBlock } from '../../atoms/atoms'
import CoursesTitles from '../../../features/Resources/Courses/CoursesTitles'
import { exercise } from '../../../types/serviceTypes'
import { useOlympiaDispatch } from '../../../app/hooks'
import { selectCourse } from '../../../features/Resources/Courses/courseSlice'

type CoursesListProps = {
  isNewCourse?: boolean
  setIsNewCourse: (isOpen: boolean) => void
  setSelectedItem: (item: exercise) => void
  type?: 'Users' | 'Exercises'
}

const CoursesList:RFC<CoursesListProps> = ({ isNewCourse, setIsNewCourse, setSelectedItem, type }) => {
  const [ searchText, setSearchText ] = useState('')
  const onChangeText = useMemo(() => debounce(setSearchText, 500), [setSearchText])
  const dispatch = useOlympiaDispatch()

  return (
    <>
      <OlympiaTitleBlock 
        title={type ?? 'Class'}
        btnTitle='New'
        onClick={() => [setSelectedItem({id: ''}), setIsNewCourse(true)]}
      />
      <OlympiaSearchbar onChange={onChangeText} />
      <CoursesTitles
        type={type ?? 'Exercises'}
        setSelectedItem={(item) => [setIsNewCourse(false), setSelectedItem(item)]}
        // isNewCourse={isNewCourse}
        searchText={searchText}
        // onRemoveNew={() => setIsNewCourse(false)} 
      />
    </>
  )
}

export default CoursesList