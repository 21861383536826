const communityIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="people_alt_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
        >
            <g id="Group_483" data-name="Group 483">
                <rect id="Rectangle_641" data-name="Rectangle 641" width="24" height="24" fill="none"/>
            </g>
            <g id="Group_485" data-name="Group 485">
                <g id="Group_484" data-name="Group 484">
                <circle id="Ellipse_68" data-name="Ellipse 68" cx="2" cy="2" r="2" transform="translate(7 6)" fill="#d6a156" opacity="0.3"/>
                <path id="Path_85" data-name="Path 85" d="M9,15c-2.7,0-5.8,1.29-6,2.01V18H15V17C14.8,16.29,11.7,15,9,15Z" fill="#d6a156" opacity="0.3"/>
                <path id="Path_86" data-name="Path 86" d="M16.67,13.13A4.651,4.651,0,0,1,19,17v3h4V17C23,14.82,19.43,13.53,16.67,13.13Z" fill='#f4de30'/>
                <path id="Path_87" data-name="Path 87" d="M15,12a4,4,0,0,0,0-8,4.178,4.178,0,0,0-1.33.24,5.98,5.98,0,0,1,0,7.52A4.178,4.178,0,0,0,15,12Z" fill='#f4de30'/>
                <path id="Path_88" data-name="Path 88" d="M9,12A4,4,0,1,0,5,8,4,4,0,0,0,9,12ZM9,6A2,2,0,1,1,7,8,2.006,2.006,0,0,1,9,6Z" fill='#f4de30'/>
                <path id="Path_89" data-name="Path 89" d="M9,13c-2.67,0-8,1.34-8,4v3H17V17C17,14.34,11.67,13,9,13Zm6,5H3v-.99C3.2,16.29,6.3,15,9,15s5.8,1.29,6,2Z" fill='#f4de30'/>
                </g>
            </g>
        </svg>
      </div>
    )
  }
  
  export default communityIcon