import { DashboardType, RFC } from '../../types/propTypes'
import { CardError, MenuCard, OlympiaTitleBlock } from '../atoms/atoms'

type DashboardTitlesProps = Pick<DashboardType, 'menu'> & {
    title: string
    onClick: (titlePage: string) => void
}

const DashboardTitles:RFC<DashboardTitlesProps> = ({ title, menu, onClick }) => {

    const renderBoardTitles = () => {
        if (!menu) return <CardError />
        return (
            <div className='w-full'>
                {menu?.map((m, index) => (
                    <MenuCard 
                        key={index}
                        title={m.title}
                        onClick={() => onClick(m.titlePage)}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            <OlympiaTitleBlock title={title} onClick={() => null}/>
            { renderBoardTitles() }
        </>
    )
}

export default DashboardTitles