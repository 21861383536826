import OlympiaLoading from "../OlympiaLoading"

const CardLoading = () => {
    return (
        <div
            className='felx justify-center items-center text-center mx-auto w-full p-5 mt-8'
        >
            <OlympiaLoading shape='bars'/>
            <p className='mt-4 text-OlympiaDarkPurple font-bold'>
            ... Loading
            </p>
        </div>
    )
}

export default CardLoading