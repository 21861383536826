import { RFC } from '../../../types/propTypes'

type OlympiaDetailTitleProps = {
    title:   string
    details: string
    margin?: string
}

const OlympiaDetailTitle:RFC<OlympiaDetailTitleProps> = ({ title, details, margin }) => {
    return (
        <div className={`w-full ${margin}`}>
            <p className='font-semibold text-OlympiaBlack'>
                {title}
            </p>
            <p className='text-[0.9em] text-OlympiaBlack/80'>
                {details}
            </p>
        </div>
    )
}

export default OlympiaDetailTitle