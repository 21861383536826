import '../../assets/styles/animation.css'
import { useNavigate } from 'react-router-dom'
// import { useAdminLoginMutation } from '../../services/AuthService'
import { useFormik } from 'formik'
import { InitialLoginState } from '../../assets/data/InitialState'
import { LoginSchema } from '../../utils/validationSchema'
import asyncTimeout from '../../utils/asyncTimeout'
import { Logo } from '../../assets/images/images'
import { LoginField } from '../../components/molecules/molecules'
import { useOlympiaDispatch } from '../../app/hooks'
import { closeSnackbar, openSnackbar } from '../../features/Snackbar/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_BUTTON_TYPES, SNACKBAR_TYPES } from '../../assets/data/enums'
import { useMutation } from '@apollo/client'
import { AdminLogin } from '../../services/APIEndpoints'
import { link } from 'fs'
import AuthService from '../../services/AuthService'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useOlympiaDispatch()

    // const [ adminLogin ] = useAdminLoginMutation()
    // const [adminLogin] = useMutation(AdminLogin)

    const formik = useFormik({
        initialValues: InitialLoginState,
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            try {
                const [ data ] = await Promise.all([
                    AuthService.Login(values.email, values.password),
                    asyncTimeout(1500)
                ])
                console.log("login data", data)
                if (!data.AdminSignin) throw Error
                localStorage.setItem('OlympiaAdminToken', data.AdminSignin)
                navigate('/training-board')
            } catch (error) {
                console.log("error loggin in", error)
                dispatch(openSnackbar({
                    snackbarType: SNACKBAR_TYPES.ERROR,
                    snackbarMessage: ERROR_MESSAGES.LOGIN,
                    snackbarTimeout: 10000,
                    snackbarBtnText: SNACKBAR_BUTTON_TYPES.RETRY,
                    snackbarBtnFunc: () => {
                        formik.setFieldValue('email', '')
                        formik.setFieldValue('password', '')
                        dispatch(closeSnackbar())
                    }
                }))
            }
        }
    })

    return (
        <div 
            className='min-h-screen w-full flex justify-center items-center 
            bg-gradient-to-br from-OlympiaSunYellow/50 to-OlympiaDarkGreyBlue/70 
            background-animate'
        >
            <div 
                className='w-[480px] mx-auto flex justify-center items-center flex-col 
                bg-OlympiaDarkGreyBlue/50 rounded-[10px] px-5 py-3 drop-shadow-xl shadow-lg'
            >
                <div className='w-full flex justify-center items-center py-4'>
                    <img src={Logo} className='w-auto h-auto max-h-[100px] max-w-[90px]' alt='WHM-purple'/>
                </div>
                <div className='w-full flex text-SuggesterfyRed font-bold text-3xl mt-2'>
                    <span className='animate-waving-hand mr-3'>👋</span> Welcome Back
                </div>
                <div className='w-full text-xl font-medium text-SuggesterfyDark'>
                Enter your email and password to enter.
                </div>
                <LoginField formik={formik}/>
            </div>
        </div>
    )
}

export default Login