import { Modal, Snackbar } from '../../types/stateTypes'
import { ERROR_MESSAGES, MODAL_TYPES, SNACKBAR_TYPES } from './enums'
import { LoginArgs } from '../../types/serviceTypes'
import { CourseSliceType } from '../../features/Resources/Courses/courseSlice'
import { CoursesSliceType } from '../../features/Resources/Courses/coursesSlice'

export const InitialSnackbarState: Snackbar = {
    isDisplayed: false,
    snackbarType: SNACKBAR_TYPES.ERROR,
    snackbarMessage: ERROR_MESSAGES.LOGIN,
    snackbarTimeout: 10000,
    snackbarBtnText: 'CLOSE',
    snackbarBtnFunc: () => {}
}

export const InitialModalState: Modal = {
    isDisplayed: true,
    modalType: MODAL_TYPES.ADD_LESSON,
    // modalType: null,
}

export const InitialLoginState: LoginArgs = {
    email:    '',
    password: '',
}

export const InitialCourseState: CourseSliceType = {
    id:     '',
    course: { id: '' }
}

export const InitialCoursesState: CoursesSliceType = {
    courses: {
        data: [],
        pagination: {}
    }
}