import { OlympiaApi } from '../app/api'
import { CreateExerciseArgs, GetAllExercisesData, LessonsData, PaginationPayload, UpdateExerciseArgs } from '../types/serviceTypes'
import { AdminLogin, CreateExercise, DeleteExercise, GetAllExercises, UpdateExercise } from './APIEndpoints'

export const exerciseApi = OlympiaApi.injectEndpoints({
    endpoints: (builder) => ({
        getExercises: builder.query<GetAllExercisesData, string>({
            query: (args) => ({
                document: GetAllExercises,
                variables: {
                    name: args
                }
            }), providesTags: ['Exercises']
        }),
        updateExercises: builder.mutation<GetAllExercisesData, UpdateExerciseArgs>({
            query: (args) => ({
                document: UpdateExercise,
                variables: args,
            }), invalidatesTags: ['Exercises']
        }),
        deleteExercise: builder.mutation<string, string>({
            query: (args) => ({
                document: DeleteExercise,
                variables: {
                    exerciseDeleteId: args
                }
            }), invalidatesTags: ['Exercises']
        }),
        createExercise: builder.mutation<string, CreateExerciseArgs>({
            query: (args) => (console.log("ehehe", args),{
                document: CreateExercise,
                variables: args
            }), invalidatesTags: ['Exercises']
        }),
        invalidateExercises: builder.mutation<null, void>({
            queryFn: () => ({ data: null }),
            invalidatesTags: ['Exercises']
        }),
    })
})

export const {
    useLazyGetExercisesQuery,
    useUpdateExercisesMutation,
    useDeleteExerciseMutation,
    useInvalidateExercisesMutation,
    useCreateExerciseMutation,
} = exerciseApi