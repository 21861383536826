import * as Yup from 'yup'

export const LoginSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Invalid email address'),
    password: Yup.string().required('Password is required')
})

export const UpdateExerciseSchema = Yup.object({
    title: Yup.string().required('Required'),
    equipmentNumber: Yup.string().required('Required'),
    primary: Yup.string().required('Required'),
    secondary: Yup.string().required('Required'),
    movement: Yup.string().required('Required'),
    image: Yup.string().required('Required').min(2, 'Required'),
})

export const UpdateUserSchema = Yup.object({
    FullName: Yup.string().required('Required'),
    Gender: Yup.string().required('Required'),
    Email: Yup.string().required('Required'),
    // Avatar: Yup.string().required('Required').min(2, 'Required'),
})