import { ErrorIcon, PasswordVisibilityIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'

type TextInputProps = {
    label:        string
    name:         string
    type:         string
    value?:       string
    required?:    boolean
    isVisible?:   boolean
    touched?:     boolean | undefined
    error?:       string | undefined | null
    onIconPress?: (visible: boolean) => void
    onChange:     (text: string) => void
}

const OlympiaTextInput:RFC<TextInputProps> = ({
    label,
    name, 
    type,
    value,
    required,
    isVisible,
    touched,
    error,
    onIconPress,
    onChange,
}) => {
    return (
        <>
            <div className='relative w-full mt-4 drop-shadow-sm'>
                <input 
                    name={name}
                    type={type}
                    value={value}
                    required={required}
                    onChange={(e) => onChange(e.target.value)}
                    className={`w-full px-5 pt-6 pb-2 pr-10 bg-OlympiaDarkWhite rounded-lg outline-none
                    text-[1.4em] border-none ${(touched && error) ? 'shadow-error' : 'shadow-normal'}
                    ${(touched && error) ? 'focus:shadow-error' : 'focus:shadow-focused'} peer`
                    }
                />
                <span
                    className='absolute text-[1.1em] left-0 px-5 py-6 text-OlympiaDarkGrey
                    transition-all duration-[0.4s] pointer-events-none ease-in-out
                    peer-focus:text-OlympiaDark peer-focus:translate-x-0 peer-focus:-translate-y-2
                    peer-focus:text-[0.9em] peer-focus:py-3 peer-focus:tracking-wider
                    peer-valid:text-OlympiaDark peer-valid:translate-x-0 peer-valid:-translate-y-2
                    peer-valid:text-[0.9em] peer-valid:py-3 peer-valid:tracking-wider'
                >
                { label }
                </span>
                {onIconPress && (
                    <div
                        className='absolute right-4 top-[1.2em] w-6 h-6 cursor-pointer'
                        onClick={() => onIconPress(!isVisible)}
                    >
                        <PasswordVisibilityIcon />
                    </div>
                )}
            </div>
            {(touched && error) && (
                <div
                    className='w-full h-12 flex items-center text-OlympiaRed 
                    font-medium text-lg'
                    role="alert"
                >
                    <ErrorIcon className='mr-2' width={'28'} height={'28'}/>
                    {error}
                </div>
            )}
        </>
    )
}

export default OlympiaTextInput