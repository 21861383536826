import { useRef, useState } from 'react'
import { InputChangeEventHandler, RFC } from '../../../types/propTypes'
// import { useLazyGetUploadFileQuery } from '../../../services/FileService'
import axios from 'axios'
import { useOlympiaDispatch } from '../../../app/hooks'
import { updateCourseImage } from '../../../features/Resources/Courses/courseSlice'
import { BinIcon, CameraIcon } from '../../../assets/icons/icons'
import { OlympiaButton } from '../atoms'
import FileService from '../../../services/FileService'

type OlympiaUploadInputProps = {
    type:     'image' | 'video'
    source:   string
    onChangeImage: (key: string) => void
    onChangeVideo: (video: string) => void
}

const OlympiaUploadInput:RFC<OlympiaUploadInputProps> = ({ type, source, onChangeImage, onChangeVideo }) => {
    const [ isUploading, setIsUploading ] = useState(false)

    const handleUpload: InputChangeEventHandler = async ({ target }) => {
        try {
            setIsUploading(true)
            const files = target.files
            if (!files) return
            const file = files[0]

            console.log("surely type", type)

            if (file.type.substring(0, 5) === 'video' && type === 'image') throw Error
            if (file.type.substring(0, 5) === 'image' && type === 'video') throw Error
            const key = await FileService.uploadImage(file)
            console.log("THEY TYPESA", file.type.substring(0,5), file.type)
            if (type === 'video'){
                onChangeVideo(key)
            }
            else {
                onChangeImage(key)
            }
        } catch (error) {
            console.log({error})
        } finally {
            setIsUploading(false)
        }
    }

    const input = useRef<HTMLInputElement>(null)

    return (
        <div>
            {(
            <div className='flex items-center'>
                <div className="relative mr-2 hover:scale-105 transition-all duration-200">
                    <input
                        ref={input}
                        type='file'
                        accept={`${type}/*`}
                        style={{zIndex: -1, position: 'absolute', opacity: 0}}
                        onChange={handleUpload}
                        disabled={isUploading}
                        
                    />
                    <OlympiaButton 
                        text='Upload'
                        className='small-purple-button'
                        rightIcon={<CameraIcon />}
                        onSubmit={() => input.current?.click()}
                    />
                </div>
            </div>
            )}
        </div>
    )
}

export default OlympiaUploadInput