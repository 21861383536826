export enum SNACKBAR_TYPES {
    ERROR   = 'error',
    WARNING = 'warning',
    INFO    = 'info',
    SUCCESS = 'success',
}

export enum SNACKBAR_BUTTON_TYPES {
    CLOSE   = 'CLOSE',
    RETRY   = 'RETRY',
    FIX     = 'FIX',
    DISMISS = 'DISMISS',
    UNDO    = 'UNDO',
    RESEND  = 'RESEND',
}

export enum ERROR_MESSAGES {
    LOGIN       = 'Failed to Login. Please check your details and try again.',
    SIGNUP      = 'Failed to register your account. Please check your details and try again.',
    MESSAGE     = 'Failed to send a message. Please check your network and try again.',
    DISMISS     = 'Failed to dismiss this support ticket. Please try again later.',
    DELETE_POST = 'Failed to delete this post. Please try again later.',
    KEEP_POST   = 'Failed to keep this post. Please try again later.',
    CONTACT     = 'Failed to send a message. You cannot contact a banned user.'
}

export enum MODAL_TYPES  {
    ADD_LESSON = 'ADD_LESSON'
}

export enum NOTICE_BOARD_TITLES {
    PROGRAM_FEEDBACK = 'program_feedback',
    HELP_TICKETS     = 'help_tickets'
}

export enum TRAINING_BOARD_TITLES {
    PROGRAM   = 'programs',
    WORKOUTS  = 'workouts',
    EXERCISES = 'exercises'
}

export enum RESOURCES_BOARD_TITLES {
    COURSES = 'courses',
    LESSONS = 'lessons'
}

export enum COMMUNITY_BOARD_TITLES {
    REPORTS = 'reports',
    GROUPS  = 'groups'
}

export enum USERS_BOARD_TITLES {
    USER_STATISTICS = 'user_statistics',
    ALL_USERS       = 'all_users'
}

export enum ALL_TITLES {
    PROGRAM_FEEDBACK = 'program_feedback',
    HELP_TICKETS     = 'help_tickets',
    PROGRAM          = 'programs',
    WORKOUTS         = 'workouts',
    EXERCISES        = 'exercises',
    COURSES          = 'courses',
    LESSONS          = 'lessons',
    REPORTS          = 'reports',
    GROUPS           = 'groups',
    USER_STATISTICS  = 'user_statistics',
    ALL_USERS        = 'all_users',
}