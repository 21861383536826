import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialModalState } from '../../assets/data/InitialState'
import { Modal } from '../../types/stateTypes'

type ModalPayload = Omit<Modal, 'isDisplayed'>

export const modalSlice = createSlice({
    name: 'modal',
    initialState: InitialModalState,
    reducers: {
        openModal: (state, action:PayloadAction<ModalPayload>) => {
            state.isDisplayed = true
            state.modalType   = action.payload.modalType
        },
        closeModal: (state) => {
            state.isDisplayed = false
        }
    }
})

export const modalSelector = (state:RootState) => state.modal
export const {
    openModal,
    closeModal
} = modalSlice.actions
export default modalSlice.reducer