import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthRequiredRoutes, NotAuthRoutes } from './utils/CustomRoutes'
import { Community, Login, NoticeBoard, Resources, Training, Users } from './pages/pages'
import WHMSnackbar from './components/atoms/OlympiaSnackbar'

const App = () => {

  return (
    <Router>
      <WHMSnackbar position='bottom_left' />

      <Routes>
        <Route path='/' element={<Navigate to='/login' replace />}/>

        <Route element={<NotAuthRoutes />}>
          <Route path='/login' element={<Login />} />
        </Route>

        <Route element={<AuthRequiredRoutes />}>
          <Route path='/training-board' element={<Training />} />
          <Route path='/community-board' element={<Community />} />
        </Route>

        <Route path='*' element={<Login />} />
      </Routes>
    </Router>
  )
}

export default App