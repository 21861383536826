import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialCourseState } from '../../../assets/data/InitialState'
import { CourseData } from '../../../types/serviceTypes'
import { Lesson } from '../../../types/stateTypes'

export type CourseSliceType = {
    id:     string
    course: CourseData
}

export const courseSlice = createSlice({
    name: 'course',
    initialState: InitialCourseState,
    reducers: {
        setCourseId: (state, action:PayloadAction<string>) => {
            state.id = action.payload
        },
        selectCourse: (state, action:PayloadAction<CourseSliceType>) => {
            state.id     = action.payload.id
            state.course = action.payload.course
        },
        clearCourse: (state) => {
            state.id     = ''
            state.course = { id: '' }
        },
        updateCourseTitle: (state, action:PayloadAction<string>) => {
            state.course.title = action.payload
        },
        updateCourseFocus: (state, action:PayloadAction<string>) => {
            state.course.focus = action.payload
        },
        updateCourseImage: (state, action:PayloadAction<string>) => {
            state.course.image = action.payload
        },
        updateCourseDescription: (state, action:PayloadAction<string>) => {
            state.course.description = action.payload
        },
        updateCourseActive: (state, action:PayloadAction<boolean>) => {
            state.course.isActive = action.payload
        },
        addCourseLessons: (state, action:PayloadAction<Lesson>) => {
            state.course.lesson?.push(action.payload)
        },
        sortCourseLessons: (state, action:PayloadAction<Lesson[]>) => {
            state.course.lesson = action.payload
        },
        removeCourseLesson: (state, action:PayloadAction<Lesson>) => {
            const filteredLessons = state.course.lesson?.filter(lesson => lesson.id !== action.payload.id)
            state.course.lesson   = filteredLessons
        }
    }
})

export const courseSelector = (state: RootState) => state.course
export const {
    selectCourse,
    clearCourse,
    updateCourseTitle,
    updateCourseFocus,
    updateCourseImage,
    updateCourseDescription,
    updateCourseActive,
    addCourseLessons,
    sortCourseLessons,
    removeCourseLesson,
} = courseSlice.actions
export default courseSlice.reducer