const cameraIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="add_a_photo_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '16'} 
            height={height ?? '16'} 
            viewBox="0 0 16 16"
        >
            <path id="Path_61" data-name="Path 61" d="M0,0H16V16H0Z" fill="none"/>
            <path id="Path_62" data-name="Path 62" d="M5.667,6.667v2H5v6.667H15.667v-8h-2.7L11.747,6H7.667v.667ZM10.333,8A3.333,3.333,0,1,1,7,11.333,3.335,3.335,0,0,1,10.333,8Z" transform="translate(-1.667 -2)" fill="#d6a156" opacity="0"/>
            <path id="Path_63" data-name="Path 63" d="M14,4.333H11.887L10.667,3H6V4.333h4.08L11.3,5.667H14v8H3.333V7H2v6.667A1.337,1.337,0,0,0,3.333,15H14a1.337,1.337,0,0,0,1.333-1.333v-8A1.337,1.337,0,0,0,14,4.333ZM5.333,9.667A3.333,3.333,0,1,0,8.667,6.333,3.335,3.335,0,0,0,5.333,9.667Zm3.333-2a2,2,0,1,1-2,2A2.006,2.006,0,0,1,8.667,7.667ZM3.333,6.333v-2h2V3h-2V1H2V3H0V4.333H2v2Z" transform="translate(0 -0.333)" fill="#fff"/>
        </svg>
      </div>
    )
  }
  
  export default cameraIcon