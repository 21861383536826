import { RFC } from '../../../types/propTypes'
import { Listbox, Transition } from '@headlessui/react'
import { DropdownArrow } from '../../../assets/icons/icons'

type OlympiaDropdownProps = {
  value:   string
  list:    string[]
  onClick: (option:string) => void 
  errorMessage?: string
}

const OlympiaDropdown:RFC<OlympiaDropdownProps> = ({
  value,
  list,
  onClick,
  errorMessage
}) => {
  
  return (
    <>
    <Listbox
      value={value}
      onChange={onClick}
      >
      {({ open }) => (
      <>
        <div 
          className='w-full relative flex bg-OlympiaDarkWhite rounded-lg px-3 py-[6px]
          border-OlympiaBorder border-[1px] border-solid shadow-sm'
        >
          <Listbox.Button className={`w-full text-start ${value === 'Focus'
          ? 'text-OlympiaDarkGrey'
          : 'text-OlympiaDarkPurple'}`}
          >
            {value}
          </Listbox.Button>
          <DropdownArrow className={`absolute right-1
          ${open ? 'rotate-180' : ''} transition-all duration-300`}/>
        </div>

        <Transition
          show={open}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Listbox.Options
            className='border-OlympiaBorder border-[1px] border-solid rounded-lg p-1'
            static
          >
            {list.map((focus, index) => (
              <Listbox.Option
                key={index}
                value={focus}
              >
                {({active, selected}) => (
                  <div
                    className={`px-3 py-1 cursor-pointer rounded-lg ${selected 
                      ? 'bg-OlympiaPurple text-OlympiaWhite' 
                      : 'hover:bg-OlympiaDarkWhite'
                    }`}
                  >
                    {focus}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </>
      )}
    </Listbox>
    {errorMessage && <div>{errorMessage}</div>}
    </>
  )
}

export default OlympiaDropdown