import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

const baseQuery = graphqlRequestBaseQuery({
    url: 'https://production.olympia.link/api',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('OlympiaAdminToken')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 })

export const OlympiaApi = createApi({
    reducerPath: 'OlympiaApi',
    baseQuery: baseQueryWithRetry,
    tagTypes: [ 'Course', 'Lesson', 'Exercises', 'Users' ],
    endpoints: () => ({})
})
