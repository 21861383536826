const arrowRightIcon = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '15'}
            height={height ?? '15'}
            viewBox="0 0 15 15"
        >
            <g id="arrow_back_black_24dp" transform="translate(15 15) rotate(180)">
                <path id="Path_41" data-name="Path 41" d="M0,0H15V15H0Z" transform="translate(0 0)" fill="none"/>
                <path id="Path_42" data-name="Path 42" d="M9.827,4.3H2.352L5.786.866,4.914,0,0,4.914,4.914,9.827l.866-.866L2.352,5.528H9.827Z" transform="translate(2.586 2.586)"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default arrowRightIcon