import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useOlympiaDispatch, useOlympiaSelector } from '../../../app/hooks'
import { courseFocusList, isLightWeightPB, type } from '../../../assets/data/arrays'
import { courseSelector, selectCourse } from '../../../features/Resources/Courses/courseSlice'
import { OlympiaDetailInput, OlympiaDetailTitle, OlympiaDropdown, OlympiaLoading, OlympiaTitleBlock, OlympiaUploadInput } from '../../atoms/atoms'
import StatusButtons from '../../molecules/StatusButtons'
import DashboardDetails from '../../templates/DashboardDetails'
import { useCreateCourseMutation, useLazyGetCourseQuery } from '../../../services/CourseService'
import InputSkeleton from '../../molecules/Skeletons/InputSkeleton'
import ImageSkeleton from '../../molecules/Skeletons/ImageSkeleton'
import { exercise } from '../../../types/serviceTypes'
import FileService from '../../../services/FileService'
import { useFormik } from 'formik'
import { UpdateExerciseSchema } from '../../../utils/validationSchema'
import { useCreateExerciseMutation, useDeleteExerciseMutation, useInvalidateExercisesMutation, useUpdateExercisesMutation } from '../../../services/ExerciseService'
import { closeSnackbar, openSnackbar } from '../../../features/Snackbar/snackbarSlice'
import { SNACKBAR_TYPES } from '../../../assets/data/enums'
import asyncTimeout from '../../../utils/asyncTimeout'

type ExerciseIntroductionProps = {
    isNewCourse?: boolean
    selectedExercise?: exercise
}

const ExerciseIntroduction:RFC<ExerciseIntroductionProps> = ({ isNewCourse, selectedExercise }) => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false)
    const [updateExercise, {isLoading: updateExerciseLoading}] = useUpdateExercisesMutation()
    const [deleteCurrentExercise] = useDeleteExerciseMutation()
    const [invalidate] = useInvalidateExercisesMutation()
    const [createExercise] = useCreateExerciseMutation()
    console.log("is news??", isNewCourse)
    const [displayImage, setDisplayImage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Global States
    const courseState = useOlympiaSelector(courseSelector)
    const dispatch = useOlympiaDispatch()

    // Services
    const [ getCourseQuery, { data: courseData, isLoading: courseLoading }] = useLazyGetCourseQuery()
    const [ createCourse ] = useCreateCourseMutation()

    useEffect(() => {
        courseData && dispatch(selectCourse({ id: courseData.id, course: courseData }))
        if (!selectedExercise) return
        getImageFromKey(selectedExercise?.Image!)
        setInitialValues()
    },[selectedExercise])

    const setInitialValues = () => {
        if (isNewCourse) {
            setFieldValue('title', '')
            setFieldValue('equipmentNumber', '')
            setFieldValue('primary', '')
            setFieldValue('secondary', '')
            setFieldValue('movement', 'Legs')
            setFieldValue('image', '1')
            setFieldValue('video', 'No Vieo')
            setFieldValue('type', 'Fat Burn')
            setFieldValue('isLightWeightPB', 'False')
            return
        }
        setFieldValue('title', selectedExercise?.Name)
        setFieldValue('equipmentNumber', selectedExercise?.equipmentNumber)
        setFieldValue('primary', selectedExercise?.Primary)
        setFieldValue('secondary', selectedExercise?.Secondary)
        setFieldValue('movement', selectedExercise?.Movement)
        setFieldValue('image', selectedExercise?.Image)
        setFieldValue('video', selectedExercise?.Video)
        setFieldValue('type', selectedExercise?.Type)
        setFieldValue('isLightWeightPB', (selectedExercise?.isLightWeightPB === 1 ? 'True' : 'False'))
    }

    const getImageFromKey = async (imageKey: string) => {
        if (!selectedExercise) return
        if (!imageKey){
            setDisplayImage('')
            return
        }

        setIsLoading(true)
        try {
            const image = await FileService.getImage(imageKey)
            console.log("display image", image)
            setDisplayImage(image)
        } catch (error) {
            console.log("error getting image", error)
        }
        setIsLoading(false)
    }

    const createNewCourse = async () => {
        try {
            const data = await createExercise({
                exercise: {
                    Name: values.title,
                    Image: values.image,
                    Primary: values.primary,
                    Secondary: values.secondary,
                    equipmentNumber: values.equipmentNumber,
                    Movement: values.movement,
                    Video: values.video,
                    isLightWeightPB: values.isLightWeightPB === "False" ? false : true,
                    Type: values.type
                }}).unwrap()
                dispatch(openSnackbar({
                    snackbarType: SNACKBAR_TYPES.SUCCESS,
                    snackbarMessage: 'Successfully created course.',
                    snackbarTimeout: 5000,
                    snackbarBtnFunc: () => {
                        dispatch(closeSnackbar())
                    }
                }))
                await asyncTimeout(3000)
                window.location.reload();
            console.log("the data", data)
        } catch (error) {
            console.log("error creating exercise", error)
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.ERROR,
                snackbarMessage: 'Error creating exercise.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }))
        }
    }

    const saveCourse = async () => {
        setShouldValidateOnChange(true)
        if (!selectedExercise || !values.title || !values.movement || !values.primary || !values.secondary || !values.type){
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.WARNING,
                snackbarMessage: 'Must fill out all fields.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }));
            return
        }
        try {
            const data = await updateExercise(
                {
                    exercise: {
                        id: selectedExercise.id, 
                        Name: values.title,
                        Image: values.image,
                        Primary: values.primary,
                        Secondary: values.secondary,
                        equipmentNumber: parseInt(values.equipmentNumber?.toString()!),
                        Movement: values.movement,
                        Video: values.video,
                        isLightWeightPB: values.isLightWeightPB === "False" ? false : true,
                        Type: values.type
                    }
                }).unwrap()
            console.log("the data", data)
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.SUCCESS,
                snackbarMessage: 'Successfully updated exercise.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }))
        } catch (error) {
            console.log("update error", error)
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.ERROR,
                snackbarMessage: 'Error updating exercise.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }))
        }
    }

    const deleteExercise = async () => {
        if (!selectedExercise) return
        dispatch(closeSnackbar())
        try {
            const data = await deleteCurrentExercise(selectedExercise?.id!).unwrap()
            invalidate()
            window.location.reload();
            console.log("the data", data)
        } catch (error) {
            console.log("error deleting exercise", error)
        }
    }

    const { values, errors, handleSubmit, setFieldValue } = useFormik({

        initialValues: isNewCourse ?
        {
            title: '',
            equipmentNumber: '',
            primary: '',
            secondary: '',
            movement: '',
            image: '1',
            type: '',
            video: 'No Video',
            isLightWeightPB: '',
        } :
        {
            title: selectedExercise?.Name,
            equipmentNumber: selectedExercise?.equipmentNumber,
            primary: selectedExercise?.Primary,
            secondary: selectedExercise?.Secondary!.replaceAll('/', '#'),
            movement: selectedExercise?.Movement!.replaceAll('/', '#'),
            image: selectedExercise?.Image,
            type: selectedExercise?.Type,
            video: selectedExercise?.Video,
            isLightWeightPB: selectedExercise?.isLightWeightPB
        },
        onSubmit:() =>  {isNewCourse ? createNewCourse() : saveCourse()},
        validateOnChange: shouldValidateOnChange,
        validationSchema: UpdateExerciseSchema
    })

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <OlympiaTitleBlock title='Exercise Information' onClick={() => console.log("gammin")} />
                {courseLoading ? (<InputSkeleton />) : (
                    <StatusButtons
                        type='d-as-s' 
                        isActive={true}
                        onSave={handleSubmit}
                        hideDelete={isNewCourse}
                        onDelete={() => 
                            dispatch(openSnackbar({
                            snackbarType: SNACKBAR_TYPES.ERROR,
                            snackbarMessage: 'Are you sure you want to delete this exercise? This action cannot be un-done.',
                            snackbarTimeout: 15000,
                            snackbarBtnText: 'DELETE',
                            snackbarBtnFunc: () => {
                                deleteExercise()
                            }
                        }))}
                        // onChangeStatus={() => dispatch(updateCourseActive(false))}
                    />
                )}
            </div>

            {!selectedExercise ?  <div>Select an exercise.</div> :
            <DashboardDetails template='grid-cols-2sm'>
            <>
                {/* Courses Detail Left Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Title' 
                            details='Add a title that describes your exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Title'
                                value={values.title!}
                                onChange={(text) => setFieldValue('title', text)}
                                errorMessage={errors.title}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Equipment Number' 
                            details='Add an equipment number to your exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Equipment Number'
                                value={values.equipmentNumber?.toString()!}
                                errorMessage={errors.equipmentNumber}
                                onChange={(text) => setFieldValue('equipmentNumber', text)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Primary Focus' 
                            details='Add a primary focus to your exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Primary Focus'
                                errorMessage={errors.primary}
                                value={isNewCourse ? (values.primary! ?? selectedExercise.Primary) : (values.primary! ?? selectedExercise.Primary).replaceAll('#', '/')}
                                onChange={(text) => setFieldValue('primary', text)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Secondary Focus' 
                            details='Add a secondary Focus to your exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Secondary Focus'
                                errorMessage={errors.secondary}
                                value={isNewCourse ? (values.secondary! ?? selectedExercise.Secondary) : (values.secondary! ?? selectedExercise.Secondary).replaceAll('#', '/')}
                                onChange={(text) => setFieldValue('secondary', text)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Movement' 
                            details='Add a movement to categorise this exercise and help users find it.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDropdown 
                                value={values.movement! ?? selectedExercise.Movement}
                                list={courseFocusList}
                                errorMessage={errors.movement}
                                onClick={(option) => setFieldValue('movement', option)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Type' 
                            details='Add a Type to your exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDropdown 
                                value={values.type! ?? selectedExercise.Type}
                                list={type}
                                errorMessage={errors.type}
                                onClick={(option) => setFieldValue('type', option)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Is Light Weight PB' 
                            details='Is this exercise a lighter weight PB?'
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDropdown 
                                value={(values.isLightWeightPB)?.toString() ?? 'False'}
                                list={isLightWeightPB}
                                errorMessage={errors.isLightWeightPB}
                                onClick={(option) => setFieldValue('isLightWeightPB', option)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Image' 
                            details='Upload an image for this exercise’s thumbnail card and overview.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<ImageSkeleton />) : (
                            <div >
                                <OlympiaUploadInput 
                                    type='image'
                                    source={values.image ?? ''}
                                    onChangeVideo={() => null}
                                    onChangeImage={(key) => [setFieldValue('image', key), getImageFromKey(key)]}
                                />
                                {isLoading ? 
                                    <OlympiaLoading shape='circle' /> :
                                    values.image ?
                                        <img style={{marginTop: 10, width: 500, height: 'auto'}} src={displayImage} alt="Image"/> :
                                        <div>No image for this exercise</div>
                                }
                                {errors.image && <div style={{color: 'red'}}>{errors.image}</div>}
                            </div>
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Video' 
                            details='Upload a video for this exercise.'
                            margin='mb-1'
                        />
                        {courseLoading ? (<ImageSkeleton />) : (
                            <div >
                                <OlympiaUploadInput 
                                    type='video'
                                    source={values.video ?? ''}
                                    onChangeImage={() => null}
                                    onChangeVideo={(video) => setFieldValue('video', video)}
                                />
                                <div style={{marginTop: 10}}>Current Video: {values.video ?? 'No Video'}</div>
                            </div>
                        )}
                    </div> 
                </div>

            </>
            </DashboardDetails>}
        </>
    )
}

export default ExerciseIntroduction