import { useEffect, useState } from 'react'
import { RFC } from '../../types/propTypes'
import { useOlympiaDispatch, useOlympiaSelector } from '../../app/hooks'
import { courseFocusList, Gender, isLightWeightPB, type } from '../../assets/data/arrays'
import { courseSelector, selectCourse } from '../../features/Resources/Courses/courseSlice'
import { useCreateCourseMutation, useLazyGetCourseQuery } from '../../services/CourseService'
import { exercise, User } from '../../types/serviceTypes'
import FileService from '../../services/FileService'
import { useFormik } from 'formik'
import { UpdateExerciseSchema, UpdateUserSchema } from '../../utils/validationSchema'
import { useCreateExerciseMutation, useDeleteExerciseMutation, useInvalidateExercisesMutation, useUpdateExercisesMutation } from '../../services/ExerciseService'
import { closeSnackbar, openSnackbar } from '../../features/Snackbar/snackbarSlice'
import { SNACKBAR_TYPES } from '../../assets/data/enums'
import asyncTimeout from '../../utils/asyncTimeout'
import StatusButtons from '../../components/molecules/StatusButtons'
import { OlympiaDetailInput, OlympiaDetailTitle, OlympiaDropdown, OlympiaTitleBlock, OlympiaUploadInput } from '../../components/atoms/atoms'
import InputSkeleton from '../../components/molecules/Skeletons/InputSkeleton'
import DashboardDetails from '../../components/templates/DashboardDetails'
import ImageSkeleton from '../../components/molecules/Skeletons/ImageSkeleton'
import OlympiaLoading from '../../components/atoms/OlympiaLoading'
import { useCreateUserMutation, useUpdateUserMutation } from '../../services/UsersService'

type ExerciseIntroductionProps = {
    isNewCourse?: boolean
    user: User
}

const UserProfile:RFC<ExerciseIntroductionProps> = ({ isNewCourse, user }) => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false)
    const [updateUser, {isLoading: updateExerciseLoading}] = useUpdateUserMutation()
    const [createUser] = useCreateUserMutation()

    const [displayImage, setDisplayImage] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Global States
    const courseState = useOlympiaSelector(courseSelector)
    const dispatch = useOlympiaDispatch()

    // Services
    const [ getCourseQuery, { data: courseData, isLoading: courseLoading }] = useLazyGetCourseQuery()
    const [ createCourse ] = useCreateCourseMutation()

    useEffect(() => {
        courseData && dispatch(selectCourse({ id: courseData.id, course: courseData }))
        if (!user) return
        getImageFromKey(user?.Avatar!)
        setInitialValues()
    },[user])

    const setInitialValues = () => {
        console.log("userasdasd", user.Avatar)
        if (isNewCourse) {
            setFieldValue('FullName', '')
            setFieldValue('Email', '')
            setFieldValue('DateOfBirth', '')
            setFieldValue('Gender', 'Female')
            setFieldValue('GymvueMemberId', '')
            setFieldValue('id', '')
            setFieldValue('DailyCalorieTarget', '')
            setFieldValue('Avatar', '')
            setFieldValue('EmergencyContractName', '')
            setFieldValue('EmergencyContractNumber', '')
            setFieldValue('EmergencyContractRelation', '')
            setFieldValue('status', '')
            setFieldValue('membership', '')
            setFieldValue('PerferredName', '')
            setFieldValue('PhoneNumber', '')
            setFieldValue('Injuries', '')
            setFieldValue('WeightStart', '')
            setFieldValue('WeightCurrent', '')
            setFieldValue('WeightGoal', '')
            return
        }
        setFieldValue('FullName', user?.FullName)
        setFieldValue('Email', user?.Email)
        setFieldValue('DateOfBirth', user?.DateOfBirth)
        setFieldValue('Gender', user?.Gender)
        setFieldValue('GymvueMemberId', user?.GymvueMemberId)
        setFieldValue('id', user?.id)
        setFieldValue('DailyCalorieTarget', user?.DailyCalorieTarget)
        setFieldValue('Avatar', user?.Avatar !== '' ? user.Avatar : '1')
        setFieldValue('EmergencyContractName', user?.EmergencyContractName)
        setFieldValue('EmergencyContractNumber', user?.EmergencyContractNumber)
        setFieldValue('EmergencyContractRelation', user?.EmergencyContractRelation)
        setFieldValue('status', user?.status)
        setFieldValue('membership', user?.membership)
        setFieldValue('PerferredName', user?.PerferredName)
        setFieldValue('PhoneNumber', user?.PhoneNumber)
        setFieldValue('Injuries', user?.Injuries)
        setFieldValue('WeightStart', user?.WeightStart)
        setFieldValue('WeightCurrent', user?.WeightCurrent)
        setFieldValue('WeightGoal', user?.WeightGoal)
    }

    const getImageFromKey = async (imageKey: string) => {
        if (!user) return
        if (!imageKey){
            setDisplayImage('')
            return
        }

        setIsLoading(true)
        try {
            const image = await FileService.getImage(imageKey)
            console.log("display image", image)
            setDisplayImage(image)
        } catch (error) {
            console.log("error getting image", error)
        }
        setIsLoading(false)
    }

    const formatNumber = (phoneNumber: number) => {
        const number = phoneNumber.toString()
        if (number.charAt(0) === '+'){
            return number.split(` `).join('')
          }
          if (number.charAt(0) === '0'){
            return `+61${number.substring(1).split(` `).join('')}`
          } else {
            return `+61${number.split(` `).join('')}`
          }
    }

    const createNewCourse = async () => {
        console.log("the full details", values)
        try {
            const data = await createUser({
                FullName: values.FullName,
                PerferredName: values.PerferredName,
                Email: values.Email,
                DateOfBirth: values.DateOfBirth,
                Gender: values.Gender,
                PhoneNumber: formatNumber(parseInt(values.PhoneNumber)),
                Password: `0${formatNumber(parseInt(values.PhoneNumber)).substring(3)}`
            }).unwrap()
            console.log("the user", data)
                dispatch(openSnackbar({
                    snackbarType: SNACKBAR_TYPES.SUCCESS,
                    snackbarMessage: 'Successfully created user.',
                    snackbarTimeout: 5000,
                    snackbarBtnFunc: () => {
                        dispatch(closeSnackbar())
                    }
                }))
                await asyncTimeout(3000)
                window.location.reload();
            console.log("the data", data)
        } catch (error) {
            console.log("error creating user", error)
        }
    }

    const saveCourse = async () => {
        console.log("this called?", values)
        setShouldValidateOnChange(true)
        if (!user || !values.FullName || !values.Email|| !values.Gender){
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.WARNING,
                snackbarMessage: 'Must fill out all fields.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }));
            return
        }
        try {
            const data = await updateUser(values).unwrap()
            console.log("the data?", data)
            dispatch(openSnackbar({
                snackbarType: SNACKBAR_TYPES.SUCCESS,
                snackbarMessage: 'Successfully updated user.',
                snackbarTimeout: 5000,
                snackbarBtnFunc: () => {
                    dispatch(closeSnackbar())
                }
            }))
        } catch (error) {
            console.log("update error", error)
        }
    }

    const { values, errors, handleSubmit, setFieldValue } = useFormik({

        initialValues: isNewCourse ?
        {
            FullName: '',
            DateOfBirth: '',
            Gender: 'Female',
            DailyCalorieTarget: '',
            Email: '',
            Avatar: '1',
            id: '',
            status: '',
            membership: '',
            PhoneNumber: '',
            PerferredName: '',
            EmergencyContractName: '',
            EmergencyContractNumber: '',
            EmergencyContractRelation: '',
            Injuries: '',
            WeightStart: '',
            WeightCurrent: '',
            WeightGoal: '',
            GymvueMemberId: ''
        } :
        {
            FullName: user?.FullName,
            DateOfBirth: user?.DateOfBirth,
            Gender: user?.Gender,
            DailyCalorieTarget: user?.DailyCalorieTarget.toString(),
            Email: user?.Email,
            Avatar: user?.Avatar !== '' ? user?.Avatar : '1',
            id: user?.id,
            status: user?.status,
            membership: user?.membership,
            PhoneNumber: user?.PhoneNumber,
            PerferredName: user?.PerferredName,
            EmergencyContractName: user?.EmergencyContractName,
            EmergencyContractNumber: user?.EmergencyContractNumber,
            EmergencyContractRelation: user?.EmergencyContractRelation,
            Injuries: user?.Injuries,
            WeightStart: user?.WeightStart.toString(),
            WeightCurrent: user?.WeightCurrent.toString(),
            WeightGoal: user?.WeightGoal.toString(),
            GymvueMemberId: user?.GymvueMemberId
        },
        onSubmit:() => {isNewCourse ? createNewCourse() : saveCourse()},
        validateOnChange: shouldValidateOnChange,
        validationSchema: UpdateUserSchema
    })

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <OlympiaTitleBlock title='User Information' onClick={() => null}/>
                {courseLoading ? (<InputSkeleton />) : (
                    <StatusButtons
                        type='d-as-s' 
                        isActive={true}
                        onSave={handleSubmit}
                        hideDelete={isNewCourse}
                        // onDelete={() => 
                        //     dispatch(openSnackbar({
                        //     snackbarType: SNACKBAR_TYPES.ERROR,
                        //     snackbarMessage: 'Are you sure you want to delete this exercise? This action cannot be un-done.',
                        //     snackbarTimeout: 15000,
                        //     snackbarBtnText: 'DELETE',
                        //     snackbarBtnFunc: () => {
                        //         deleteExercise()
                        //     }
                        // }))}
                        // onChangeStatus={() => dispatch(updateCourseActive(false))}
                    />
                )}
            </div>

            {!user ?  <div>Select a user.</div> :
            <DashboardDetails template='grid-cols-2sm'>
            <>
                {/* Courses Detail Left Side */}
                <div className='h-full overflow-y-auto overflow-x-hidden px-3 pb-4'>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Full Name' 
                            details={`User's full name.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Full Name'
                                value={values.FullName}
                                onChange={(text) => setFieldValue('FullName', text)}
                                errorMessage={errors.FullName}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Preferred Name' 
                            details={`User's preferred name.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Preferred Name'
                                value={values.PerferredName}
                                onChange={(text) => setFieldValue('PerferredName', text)}
                                errorMessage={errors.PerferredName}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Date Of Birth' 
                            details={`Year-Month-Day`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='YYYY-MM-DD'
                                value={values.DateOfBirth!}
                                errorMessage={errors.DateOfBirth}
                                onChange={(text) => setFieldValue('DateOfBirth', text.replaceAll(',', '-').replaceAll('/', '-').replaceAll('.', '-').replaceAll(' ', ''))}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Gender' 
                            details={`User's Gender.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDropdown 
                                value={values.Gender}
                                list={Gender}
                                errorMessage={errors.EmergencyContractName}
                                onClick={(option) => setFieldValue('Gender', option)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Email' 
                            details={`User's email. ${isNewCourse ? 'Please note if the email entered is already in our system the user may not be created.' : ''}`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Email'
                                errorMessage={errors.Email}
                                value={values.Email!}
                                onChange={(text) => setFieldValue('Email', text)}
                            />
                        )}
                    </div>

                    <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Phone Number'
                            details={`Primary contact number. No country code. ${isNewCourse ? 'Please note if the number entered is already in our system the user may not be created.' : ''}`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Phone Number'
                                errorMessage={errors.PhoneNumber}
                                value={values.PhoneNumber!}
                                onChange={(text) => setFieldValue('PhoneNumber', text)}
                            />
                        )}
                    </div>

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Id' 
                            details={`User's Olympia client Id.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Id'
                                errorMessage={errors.id}
                                value={values.id}
                                onChange={(text) => setFieldValue('id', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Gymvue Member Id' 
                            details={`User's Gymvue Member Id.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Gymvue Member Id'
                                errorMessage={errors.GymvueMemberId}
                                value={values.GymvueMemberId}
                                onChange={(text) => setFieldValue('GymvueMemberId', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Status' 
                            details={`User's account status.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Status'
                                errorMessage={errors.status}
                                value={values.status}
                                onChange={(text) => setFieldValue('status', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Membership' 
                            details={`User's membership type.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Membership'
                                errorMessage={errors.membership}
                                value={values.membership}
                                onChange={(text) => setFieldValue('membership', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Emergency Contact Name' 
                            details={`User's emergency contact name.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Emergency Contact Name'
                                errorMessage={errors.EmergencyContractName}
                                value={values.EmergencyContractName}
                                onChange={(text) => setFieldValue('EmergencyContractName', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Emergency Contact Relation' 
                            details={`User's relation to emergency contact.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Emergency Contact Relation'
                                errorMessage={errors.EmergencyContractRelation}
                                value={values.EmergencyContractRelation}
                                onChange={(text) => setFieldValue('EmergencyContractRelation', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Emergency Contact Number' 
                            details={`User's emergency contact primary phone number.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Emergency Contact Number'
                                errorMessage={errors.EmergencyContractNumber}
                                value={values.EmergencyContractNumber}
                                onChange={(text) => setFieldValue('EmergencyContractNumber', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Daily Calories Target' 
                            details={`User's daily calories target.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Daily Calories Target'
                                errorMessage={errors.DailyCalorieTarget}
                                value={values.DailyCalorieTarget}
                                onChange={(text) => setFieldValue('DailyCalorieTarget', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Weight Start' 
                            details={`User's initial weight.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Weight Start'
                                errorMessage={errors.WeightStart}
                                value={values.WeightStart}
                                onChange={(text) => setFieldValue('WeightStart', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Weight Current' 
                            details={`User's current weight.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Weight Current'
                                errorMessage={errors.WeightCurrent}
                                value={values.WeightCurrent}
                                onChange={(text) => setFieldValue('WeightCurrent', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Weight Goal' 
                            details={`User's weight goal.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Weight Goal'
                                errorMessage={errors.WeightGoal}
                                value={values.WeightGoal}
                                onChange={(text) => setFieldValue('WeightGoal', text)}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Injuries' 
                            details={`User's existing injuries.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<InputSkeleton />) : (
                            <OlympiaDetailInput
                                placeholder='Injuries'
                                errorMessage={errors.Injuries}
                                value={values.Injuries}
                                onChange={(text) => setFieldValue('Injuries', text.replaceAll(',', '#').replaceAll('/', '#').replaceAll('.', '#').replaceAll(' ', ''))}
                            />
                        )}
                    </div>}

                    {!isNewCourse && <div className='pb-4'>
                        <OlympiaDetailTitle 
                            title='Image' 
                            details={`Upload an image for this user's thumbnail card and overview.`}
                            margin='mb-1'
                        />
                        {courseLoading ? (<ImageSkeleton />) : (
                            <div>
                                <OlympiaUploadInput 
                                    type='image'
                                    source={values.Avatar ?? '1'}
                                    onChangeVideo={() => null}
                                    onChangeImage={(key) => [setFieldValue('Avatar', key), getImageFromKey(key)]}
                                />
                                {(displayImage !== '') && (
                                    isLoading ? <OlympiaLoading shape='circle' /> : <img style={{marginTop: 10}} src={displayImage} alt="Image"/>
                                )}
                                {errors.Avatar && <div style={{color: 'red'}}>{errors.Avatar}</div>}
                            </div>
                        )}
                    </div>}
                </div>

            </>
            </DashboardDetails>}
        </>
    )
}

export default UserProfile