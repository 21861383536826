import axios, { AxiosError } from 'axios'
import { OlympiaApi } from '../app/api'
import { CreateExerciseArgs, CreateUserArgs, GetAllExercisesData, GetUsersData, LessonsData, PaginationPayload, UpdateExerciseArgs, User } from '../types/serviceTypes'
import { AdminLogin, CreateExercise, CreateUser, DeleteExercise, GetAllExercises, GetUsers, UpdateExercise, UpdateUser } from './APIEndpoints'

export const usersService = OlympiaApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<GetUsersData, string>({
            query: (args) => (console.log("gammin", args),{
                document: GetUsers,
                variables: {
                    name: args
                }
            }), providesTags: ['Users']
        }),
        updateUser: builder.mutation<GetAllExercisesData, User>({
            query: (args) => (console.log("the args", args),{
                document: UpdateUser,
                variables: {
                    user: args
                },
            })
        }),
        deleteUser: builder.mutation<string, string>({
            query: (args) => ({
                document: DeleteExercise,
                variables: {
                    exerciseDeleteId: args
                }
            })
        }),
        createUser: builder.mutation<string, {FullName: string, Email: string, PhoneNumber: string, Password: string, PerferredName: string, Gender: string, DateOfBirth: string}>({
            
            queryFn: async (args): Promise<any> => {
                try {
                    const result = await axios.post('https://production.olympia.link/public', {
                        query: CreateUser,
                        variables: {
                            user: args
                        }
                    })
                    return { data: result.data }
                  } catch (axiosError) {
                      console.log("the error", axiosError)
                    let err = axiosError as AxiosError
                    return {
                      error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                      },
                    }
                }
            }
        }),
        invalidateUsers: builder.mutation<null, void>({
            queryFn: () => ({ data: null }),
            invalidatesTags: ['Users']
        }),
    })
})

export const {
    useLazyGetUsersQuery,
    useUpdateUserMutation,
    useCreateUserMutation
} = usersService