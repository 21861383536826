import { useEffect } from 'react'
import { RFC } from '../../../types/propTypes'
import { useOlympiaDispatch, useOlympiaSelector } from '../../../app/hooks'
import { clearCourse, courseSelector, selectCourse } from './courseSlice'
import { AnimatePresence } from 'framer-motion'
import { NewCard, OlympiaStatus } from '../../../components/atoms/atoms'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { InfiniteScrollList } from '../../../components/organisms/organisms'
import { ALL_TITLES } from '../../../assets/data/enums'
import { exercise } from '../../../types/serviceTypes'

type CoursesTitlesProps = {
  isNewCourse?: boolean
  searchText:  string
  onRemoveNew?: () => void
  setSelectedItem: (item: exercise) => void
  type?: 'Users' | 'Exercises'
}

const CoursesTitles:RFC<CoursesTitlesProps> = ({ 
  isNewCourse, 
  searchText, 
  onRemoveNew,
  setSelectedItem,
  type
}) => {
  const courseState = useOlympiaSelector(courseSelector)
  const dispatch = useOlympiaDispatch()

  useEffect(() => {
    dispatch(selectCourse({
      id: '',
      course: { id: '' }
    }))
  },[isNewCourse])

  return (
    <div className='w-full mt-4'>
      <AnimatePresence>
      <InfiniteScrollList
        setSelectedItem={(item) => setSelectedItem(item)}
        page={type === 'Exercises' ? ALL_TITLES.COURSES : ALL_TITLES.ALL_USERS}
        searchText={searchText}
        // onRemoveNew={() => onRemoveNew()}
      />
      </AnimatePresence>
    </div>
  )
}

export default CoursesTitles