import { useState } from 'react'
import { FormikType, RFC } from '../../types/propTypes'
import { LoginArgs } from '../../types/serviceTypes'
import { OlympiaButton, OlympiaTextInput } from '../atoms/atoms'

const LoginField:RFC<FormikType<LoginArgs>> = ({ formik }) => {
  const [ isVisible, setIsVisible ] = useState(false)

  return (
    <>
      <form 
        className='w-full'
        onSubmit={formik.handleSubmit}
      >
        <OlympiaTextInput
          label='EMAIL'
          name='email'
          type='text'
          value={formik.values.email}
          required={true}
          touched={formik.touched.email}
          error={formik.errors.email ?? null}
          onChange={(text) => formik.setFieldValue('email', text)}
        />
        <OlympiaTextInput 
          label='PASSWORD'
          name='password'
          type={isVisible ? 'text' : 'password'}
          value={formik.values.password}
          required={true}
          isVisible={isVisible}
          touched={formik.touched.password}
          error={formik.errors.password ?? null}
          onIconPress={setIsVisible}
          onChange={(text) => formik.setFieldValue('password', text)}
        />
        <div className='w-full mt-10 mb-2'>
          <OlympiaButton 
            text='ENTER'
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || (!formik.values.email || !formik.values.password)}
            className='login-button'
            onSubmit={formik.handleSubmit}
          />
        </div>
      </form>
    </>
  )
}

export default LoginField