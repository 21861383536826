import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/styles/index.css'
import { Provider } from 'react-redux'
import { store } from './app/store'
import App from './App'
import 'tw-elements'
import reportWebVitals from './reportWebVitals'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('OlympiaAdminToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(createUploadLink({
    uri: 'https://dev.olympia.link/'
  })),
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
)

reportWebVitals()