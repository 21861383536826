import { useState } from "react"
import { communityBoardTitles } from "../../assets/data/arrays"
import { COMMUNITY_BOARD_TITLES } from "../../assets/data/enums"
import ExerciseIntroduction from "../../components/organisms/details/ExerciseIntroduction"
import { CoursesList } from "../../components/organisms/organisms"
import DashboardLayout from "../../components/templates/DashboardLayout"
import DashboardTitles from "../../components/templates/DashboardTitles"
import { exercise } from "../../types/serviceTypes"
import UserProfile from "./UserProfile"

const Community = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(COMMUNITY_BOARD_TITLES.REPORTS)
  const [ isNewCourse, setIsNewCourse ] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>()

  const renderList = () => {
    return <CoursesList
      // isNewCourse={isNewCourse}
      type={'Users'}
      setIsNewCourse={(value) => setIsNewCourse(value)}
      setSelectedItem={(item) => setSelectedUser(item)}
    />
  }

  const renderDetails = () => {
    return <UserProfile user={selectedUser} isNewCourse={isNewCourse}/>
  }

  return (
    <DashboardLayout page='users'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Community'
            menu={communityBoardTitles}
            onClick={setCurrentPage}
          />
        </div>
        <div className='dashboard-title-box'>
          { renderList() }
        </div>
        <div className='dashboard-detail-box'>
          { renderDetails() }
        </div>
      </>
    </DashboardLayout>
  )
}

export default Community