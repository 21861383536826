import { gql } from "@apollo/client";

export const AdminLogin = `
mutation AdminSignin($email: String, $password: String) {
    AdminSignin(Email: $email, Password: $password)
  }
  `;

export const GetAllExercises = `
query Exercises($name: String) {
  Exercises(Name: $name) {
    Name
    id
    Image
    Primary
    Secondary
    equipmentNumber
    Movement
    Category
    Video
    isLightWeightPB
    Type
  }
}
`;

export const UpdateExercise = `
mutation ExerciseUpdate($exercise: ExerciseInput) {
  ExerciseUpdate(exercise: $exercise)
}
`;

export const DeleteExercise = `
mutation ExerciseDelete($exerciseDeleteId: String) {
  ExerciseDelete(id: $exerciseDeleteId)
}
`;

export const CreateExercise = `
mutation ExerciseCreate($exercise: ExerciseInput) {
  ExerciseCreate(exercise: $exercise) {
    Name
  }
}`

export const DownloadImageAPI = ``;

export const GetUsers = `
query Users($name: String) {
  users(name: $name) {
    id
    status
    membership
    PhoneNumber
    Email
    FullName
    PerferredName
    Avatar
    DateOfBirth
    EmergencyContractName
    EmergencyContractNumber
    EmergencyContractRelation
    Gender
    DailyCalorieTarget
    Injuries
    WeightStart
    WeightCurrent
    WeightGoal
    GymvueMemberId
  }
}`

export const UpdateUser = `
mutation UserUpdate($user: UserInput) {
  UserUpdate(user: $user)
}`

export const CreateUser = `
mutation UserSignup($user: UserInput) {
  UserSignup(user: $user) {
    id
  }
}`