import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { InitialCoursesState } from '../../../assets/data/InitialState'
import { CoursesData } from '../../../types/serviceTypes'

export type CoursesSliceType = {
    courses: CoursesData
}

export const coursesSlice = createSlice({
    name: 'course',
    initialState: InitialCoursesState,
    reducers: {
        setInitialCourses: (state, action:PayloadAction<CoursesSliceType>) => {
            state.courses.data = action.payload.courses.data
        },
        setCourses: (state, action:PayloadAction<CoursesSliceType>) => {
            const { data: storedCourses } = state.courses
            const { data: newCourses } = action.payload.courses
            const courseId = storedCourses.map(course => course.id)
            const filteredDuplicates = newCourses.filter(course => !courseId.includes(course.id))
            filteredDuplicates.map(course => state.courses.data.push(course))
        },
        clearCourses: (state) => {
            state.courses.data = []
            state.courses.pagination = {}
        },
    }
})

export const coursesSelector = (state: RootState) => state.courses
export const {
    setInitialCourses,
    setCourses,
    clearCourses,
} = coursesSlice.actions
export default coursesSlice.reducer