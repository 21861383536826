import { RFC } from '../../../types/propTypes'

type OlympiaDetailInputProps = {
    placeholder: string
    value:       string
    onChange:    (text: string) => void
    errorMessage?: string
}

const OlympiaDetailInput:RFC<OlympiaDetailInputProps> = ({
    placeholder,
    value,
    onChange,
    errorMessage
}) => {
  return (
    <div className='w-full'>
        <input
            type='text'
            placeholder={placeholder}
            value={value}
            className='w-full text-OlympiaDarkPurple rounded-lg 
            bg-OlympiaDarkWhite px-3 py-[6px] outline-none border-OlympiaBorder
            border-[1px] border-solid shadow-sm'
            onChange={(e) => onChange(e.target.value)}
        />
        <div style={{color: 'red'}}>{errorMessage}</div>
    </div>
  )
}

export default OlympiaDetailInput