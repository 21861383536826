import { motion } from 'framer-motion'
import { RFC } from '../../../types/propTypes'
import { RightArror } from '../../../assets/icons/icons'

type MenuCardProps = {
    title: string
    onClick: () => void,
}

const MenuCard:RFC<MenuCardProps> = ({ title, onClick }) => {

    return (
        <div
            className='w-full px-2 py-1 cursor-pointer'
            onClick={onClick}
        >
            <motion.div
                className='flex justify-between items-center p-2 
                rounded-[5px] bg-OlympiaLightGrey text-OlympiaDarkPurple 
                hover:text-OlympiaWhite hover:bg-OlympiaDarkPurple/60 
                fill-OlympiaDarkPurple hover:fill-OlympiaWhite shadow-md'
                initial={{ scale: 0.5, x: -200 }}
                animate={{ scale: 1, x: 0}}
                transition={{ 
                    type: 'spring', 
                    stiffness: 60,
                }}
            >
                <p className='font-semibold'>
                    {title}
                </p>
                <RightArror/>
            </motion.div>
        </div>
    )
}

export default MenuCard