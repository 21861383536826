const rightArrow = ({ height, width, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? "6.522"} 
            height={height ?? "10.661"} 
            viewBox="0 0 6.522 10.661"
        >
            <path id="chevron_right_FILL0_wght600_GRAD0_opsz48" d="M17.091,21.861,15.9,20.669l4.139-4.139L15.9,12.391,17.091,11.2l5.33,5.33Z" transform="translate(-15.9 -11.2)"/>
        </svg>
      </div>
    )
  }
  
  export default rightArrow