import { useState } from "react"
import { useOlympiaSelector } from "../../app/hooks"
import { trainingBoardTitles } from "../../assets/data/arrays"
import { RESOURCES_BOARD_TITLES, TRAINING_BOARD_TITLES } from "../../assets/data/enums"
import WHMEmptyDetailBox from "../../components/atoms/textBlocks/OlympiaEmptyDetailBox"
import ExerciseIntroduction from "../../components/organisms/details/ExerciseIntroduction"
import { CoursesList } from "../../components/organisms/organisms"
import DashboardLayout from "../../components/templates/DashboardLayout"
import DashboardTitles from "../../components/templates/DashboardTitles"
import { courseSelector } from "../../features/Resources/Courses/courseSlice"
import { exercise } from "../../types/serviceTypes"

const Training = () => {
  const [ currentPage, setCurrentPage ] = useState<string>(TRAINING_BOARD_TITLES.PROGRAM)
  const courseState = useOlympiaSelector(courseSelector)
  const [ isNewCourse, setIsNewCourse ] = useState(false)
  const [ isNewLesson, setIsNewLesson ] = useState(false)
  const [selectedExercise, setSelectedExercise] = useState<exercise>()

  const renderList = () => {
        return <CoursesList
          type={'Exercises'}
          isNewCourse={isNewCourse}
          setIsNewCourse={(value) => [setIsNewCourse(value), console.log("the val", value)]}
          setSelectedItem={(item) => setSelectedExercise(item)}
        />
  }

  const renderDetails = () => {
          return <ExerciseIntroduction selectedExercise={selectedExercise} isNewCourse={isNewCourse}/>
  }
  
  return (
    <DashboardLayout page='exercises'>
      <>
        <div className='dashboard-menu-box'>
          <DashboardTitles 
            title='Exercises'
            menu={trainingBoardTitles}
            onClick={setCurrentPage}
          />
        </div>
        <div className='dashboard-title-box'>
          { renderList() }
        </div>
        <div className='dashboard-detail-box'>
          { renderDetails() }
        </div>
      </>
    </DashboardLayout>
  )
}

export default Training