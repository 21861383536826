import { configureStore } from '@reduxjs/toolkit'
import { OlympiaApi } from './api'
import SanckbarReducer from '../features/Snackbar/snackbarSlice'
import ModalReducer from '../features/Modal/modalSlice'
import CourseReducer from '../features/Resources/Courses/courseSlice'
import CoursesReducer from '../features/Resources/Courses/coursesSlice'

export const store = configureStore({
    reducer: {
        [OlympiaApi.reducerPath]: OlympiaApi.reducer,
        snackbar:   SanckbarReducer,
        modal:      ModalReducer,
        course:     CourseReducer,
        courses:    CoursesReducer,
    },
    middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({ serializableCheck: false}).concat(OlympiaApi.middleware),
    devTools: true
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>