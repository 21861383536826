import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialSnackbarState } from '../../assets/data/InitialState'
import { Snackbar } from '../../types/stateTypes'

type SnackbarPayload = Omit<Snackbar, 'isDisplayed'>

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: InitialSnackbarState,
    reducers: {
        openSnackbar: (state, action:PayloadAction<SnackbarPayload>) => {
            const { 
                snackbarType, 
                snackbarMessage, 
                snackbarTimeout,
                snackbarBtnText,
                snackbarBtnFunc
            } = action.payload
            state.isDisplayed     = true
            state.snackbarType    = snackbarType
            state.snackbarMessage = snackbarMessage
            state.snackbarTimeout = snackbarTimeout
            state.snackbarBtnText = snackbarBtnText
            state.snackbarBtnFunc = snackbarBtnFunc
        },
        closeSnackbar: (state) => {
            state.isDisplayed = false
        }
    }
})

export const snackbarSelector = (state: RootState) => state.snackbar
export const {
    openSnackbar,
    closeSnackbar
} = snackbarSlice.actions
export default snackbarSlice.reducer