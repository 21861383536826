import { OlympiaApi } from '../app/api'
import { CourseCreationArgs, CourseData, CoursesData, CourseStatisticArgs, CourseStatisticData, PaginationPayload } from '../types/serviceTypes'
import { Course } from '../types/stateTypes'
import { AdminLogin } from './APIEndpoints'

export const courseApi = OlympiaApi.injectEndpoints({
    endpoints: (builder) => ({
        getCourses: builder.query<CoursesData, PaginationPayload>({
            query: (variables) => ({document: AdminLogin, variables}),
            providesTags: ['Course']
        }),
        getCourse: builder.query<CourseData, string>({
            query: (variables) => ({document: AdminLogin, variables}),
            providesTags: ['Course']
        }),
        getCourseStatistic: builder.query<CourseStatisticData, CourseStatisticArgs>({
            query: (variables) => ({document: AdminLogin, variables}),
            providesTags: ['Course']
        }),
        createCourse: builder.mutation<Course, CourseCreationArgs>({
            query: (variables) => ({document: AdminLogin, variables}),
            invalidatesTags: ['Course']
        }),
    })
})

export const {
    useLazyGetCoursesQuery,
    useLazyGetCourseQuery,
    useGetCourseStatisticQuery,
    useCreateCourseMutation,
} = courseApi