const logoutIcon = ({ width, height, color, className }: React.SVGProps<SVGSVGElement>) => {
    return (
      <div className={className}>
        <svg 
            id="logout_black_24dp" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
        >
            <g id="Group_695" data-name="Group 695">
                <path id="Path_759" data-name="Path 759" d="M0,0H24V24H0Z" fill="none"/>
            </g>
            <g id="Group_696" data-name="Group 696" transform="translate(3 3)">
                <path id="Path_760" data-name="Path 760" d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z" fill="white" transform="translate(-3 -3)"/>
            </g>
        </svg>
      </div>
    )
  }
  
  export default logoutIcon