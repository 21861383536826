import { RFC } from '../../../types/propTypes'
import { OlympiaButton } from '../atoms'

type OlympiaTitleBlockProps = {
    title:      string
    btnTitle?:  string
    onClick: () => void
    isInvisible?: boolean
}

const OlympiaTitleBlock:RFC<OlympiaTitleBlockProps> = ({ 
    title,
    btnTitle,
    onClick,
    isInvisible
}) => {

    return (
        <div className='w-full flex items-center justify-between px-3 py-2'>
            <div className='text-OlympiaDarkPurple font-bold text-[1.6em]'>
                {title}
            </div>
            {btnTitle && !isInvisible && (
                <OlympiaButton 
                    text='New'
                    className='small-dark-purple-button'
                    onSubmit={onClick}
                />
            )}
        </div>
    )
}

export default OlympiaTitleBlock