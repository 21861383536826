const ImageSkeleton = () => {
    return (
        <div
            role='status'
            className='w-full animate-pulse'
        >
            <div className='h-40 bg-OlympiaLightGrey rounded-[5px] dark:bg-OlympiaGrey w-full'></div>
        </div>
    )
}

export default ImageSkeleton