import { useEffect, useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { ALL_TITLES } from '../../../assets/data/enums'
import { useLazyGetCoursesQuery } from '../../../services/CourseService'

import { useOlympiaDispatch } from '../../../app/hooks'
import { setCourses, setInitialCourses } from '../../../features/Resources/Courses/coursesSlice'
import InfiniteScroll from 'react-infinite-scroller'
import { CardLoading, OlympiaStatus, TitleCard } from '../../atoms/atoms'
import { useLazyGetExercisesQuery } from '../../../services/ExerciseService'
import ExerciseTitles from '../../molecules/TitleCards/ExerciseTitles'
import { domAnimation, LazyMotion } from 'framer-motion'
import { ArrowRightIcon } from '../../../assets/icons/icons'
import { exercise, GetAllExercisesData, User } from '../../../types/serviceTypes'
import { useLazyGetUsersQuery } from '../../../services/UsersService'

type InfiniteScrollListProps = {
    page:       ALL_TITLES
    searchText: string
    onRemoveNew?: () => void
    setSelectedItem: (item: exercise) => void
}

const InfiniteScrollList:RFC<InfiniteScrollListProps> = ({ page, searchText, onRemoveNew, setSelectedItem }) => {
    const perPage = 10
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ isLoadMore, setIsLoadMore ] = useState(false)
    const [ hasMore, setHasMore ] = useState(true)
    const dispatch = useOlympiaDispatch()
    const [listData, setListData] = useState<any[]>([])

    // Get All Items APIs
    const [ getExercises, { data: items, isLoading: coursesLoading, isError: coursesError } ] = useLazyGetExercisesQuery()
    const [ getUsers, { data: users, isLoading: usersLoading, isError: usersError } ] = useLazyGetUsersQuery()

    useEffect(() => {
        switchService()
    },[searchText])

    const switchService = async () => {
        console.log("the big gammin")
        switch (page) {
            case ALL_TITLES.PROGRAM_FEEDBACK:
                return 
            case ALL_TITLES.HELP_TICKETS:
                return
            case ALL_TITLES.PROGRAM:
                return
            case ALL_TITLES.WORKOUTS:
                return
            case ALL_TITLES.EXERCISES:
                return
            case ALL_TITLES.COURSES:
                try {
                    const data = await getExercises(searchText).unwrap()
                    setListData(data.Exercises)
                } catch (error) {
                    console.log("error", error)
                }
                return 
            case ALL_TITLES.LESSONS:
                return
            case ALL_TITLES.REPORTS:
                return
            case ALL_TITLES.GROUPS:
                return
            case ALL_TITLES.USER_STATISTICS:
                return
            case ALL_TITLES.ALL_USERS:
                try {
                    const data = await getUsers(searchText).unwrap()
                    setListData(data.users)
                } catch (error) {
                    console.log("error", error)
                }
                return
            default:
                return
        }
    }

    const mapExercises = () => {

        return listData?.map((item, index) => {
            return (
                <TitleCard
                    key={index}
                    isSelected={false}
                    onClick={() => setSelectedItem(item)}
                >
                    <div className='flex items-center justify-between'>
                        <div className='max-w-[90%]'>
                        <p className='truncate text-2xl font-bold'>{item.Name ?? item.FullName}</p>
                        <div className='flex items-center'>
                            {/* <OlympiaStatus status={true}/> */}
                            <p className='truncate '>{item.Movement ?? item.Email}</p>
                        </div>
                        </div>
                        <ArrowRightIcon
                        width={18}
                        height={18}
                        />
                    </div>
                </TitleCard>
            )
        })
    }

    return (
        <div className='w-full h-screen overflow-y-auto overflow-x-hidden pb-[13em]'>
            <InfiniteScroll
                loadMore={() => null}
                hasMore={hasMore}
                useWindow={false}
                loader={<CardLoading key={0}/>}
                initialLoad={true}
                threshold={150}
            >
                <LazyMotion features={domAnimation}>
                    { mapExercises() }
                </LazyMotion>

            </InfiniteScroll>
        </div>
    )
}

export default InfiniteScrollList