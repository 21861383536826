import { RFC } from '../../types/propTypes'
import { OlympiaButton } from '../atoms/atoms'
import { Switch } from '@headlessui/react'

type StatusButtonsProps = {
 /* Delete        -> d 
    Save          -> s 
    Active Status -> as
    Content Title -> ct */
    type: 'd-as-s' | 'ct-d-s' | 'd-s' | 'd-as'
    title?:     string
    isActive?:  boolean
    onDelete?:       () => void
    onSave?:         () => void
    onChangeStatus?: () => void
    hideDelete?: boolean
}

const StatusButtons:RFC<StatusButtonsProps> = ({
    type,
    title,
    isActive,
    onDelete, 
    onSave,
    onChangeStatus,
    hideDelete
}) => {

    const deleteButton = () => {
        if (!onDelete || hideDelete) return
        return (
            <OlympiaButton 
            text='Delete'
            className='delete-button'
            onSubmit={onDelete}
            />
        )
    }
    
    const saveButton = () => {
        if (!onSave) return
        return (
            <OlympiaButton 
            text='Save'
            className='save-button'
            onSubmit={onSave}
            />
        )
    }
    
    const activeStatus = () => {
        if (!onChangeStatus) return
        return (
            <div 
                className='w-48 border-[1px] border-solid border-OlympiaBorder
                rounded-[5px] flex items-center justify-evenly py-[2px] px-2'
            >
                Active Status
                <Switch 
                    checked={isActive ? true : false}
                    onChange={onChangeStatus}
                    className={`${isActive ? 'bg-OlympiaBlue' : 'bg-OlympiaGrey'}
                    relative inline-flex h-8 w-14 items-center rounded-full 
                    border-[1px] border-solid border-OlympiaBorder`}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                    aria-hidden="true"
                    className={`${isActive ? 'translate-x-6' : '-translate-x-[1px]'}
                    inline-block h-8 w-8 transform rounded-full bg-OlympiaWhite border-[2px] border-solid 
                    border-OlympiaBorder transition-all duration-300`}
                    />
                </Switch>
            </div>
        )
    }
    
    const contentTitle = () => {
        return(
            <></>
        )
    }

    const renderStatusButtons = () => {
        switch (type) {
            case 'd-as-s': 
                return (
                    <div className='flex mr-4'>
                        { deleteButton() }
                        { activeStatus() }
                        { saveButton() }
                    </div>
                )
            case 'ct-d-s':
                return (
                    <></>
                )
            case 'd-s':
                return (
                    <></>
                )
            case 'd-as':
                return (
                    <></>
                )
            default:
                return
        }
    }

    return (
        <div>
            { renderStatusButtons() }
        </div>
    )
}

export default StatusButtons