import { SearchIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'

type OlympiaSearchbarProps = {
    style?: string
    onChange: (text: string) => void
}

const OlympiaSearchbar:RFC<OlympiaSearchbarProps> = ({ style, onChange }) => {
  return (
    <form className='flex items-center'>
      <div 
        className='relative w-full flex justify-between items-center  
        bg-OlympiaDarkWhite border-[1px] border-solid border-OlympiaBorder
        mx-2 rounded-lg hover:shadow-focused selection:shadow-focused
        transition-all duration-200'
      >
        <input 
          type='text'
          className={`w-full mx-1 pl-2 ${style ?? 'py-1'} bg-OlympiaDarkWhite outline-none`}
          placeholder='Search'
          onChange={(e) => onChange(e.target.value)}
        />
        <SearchIcon 
          className='flex items-center px-1 pointer-events-none text-OlympiaDarkGrey'
        />
      </div>
    </form>
  )
}

export default OlympiaSearchbar