import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

const useAuth = () => {
    // add user state
    const token = localStorage.getItem('OlympiaAdminToken')
    if (token) return true
    return false
}

export const AuthRequiredRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to='/login' />
}

export const NotAuthRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Navigate to='/training-board' /> : <Outlet/>
}