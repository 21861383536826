import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import axios from 'axios'
import { OlympiaApi } from '../app/api'
import { LoginArgs, LoginData } from '../types/serviceTypes'
import { AdminLogin } from './APIEndpoints'
import { gql } from '@apollo/client'

namespace AuthService {
    export const Login = async (email: string, password: string): Promise<{AdminSignin: string}> => {  
        try {
            const { data } = await axios.post('https://production.olympia.link/public', {
                query: AdminLogin,
                variables: {
                  email,
                  password
                }
            }) 
            return data.data
            
        } catch (error) {
            console.log("herror69", error)
            if(axios.isAxiosError(error)){
                console.log('error in service1', error.message)
  
            }
            throw Error
        }
    }
}

export default AuthService
