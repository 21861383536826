import axios from 'axios'

namespace FileService {
    const token = localStorage.getItem('OlympiaAdminToken')
    export const getImage = async (filename: string): Promise<string> => {  
        try {
            const { data } = await axios.get('https://production.olympia.link/file/aws_download/v2', {
                // query: AdminLogin,
                params: {
                  filename
                },
                // headers: {
                //     Authorization: `Bearer ${token}`,
                //   },
            }) 
            
            return data
            
        } catch (error) {
            console.log("herror69", error)
            if(axios.isAxiosError(error)){
                console.log('error in service1', error.message)
  
            }
            throw Error
        }
    }

    export const uploadImage = async (file: File) => {  
          console.log("file data", file)
            var formData = new FormData();
            formData.append('fileData', file);
          
            try {
              const response = await axios.post('https://production.olympia.link/file/aws_upload', formData, {
              });
              console.log("the big response", response)
              return response.data;
            } catch (error) {
              console.log('uploadImage', error);
            }
          };
}

export default FileService